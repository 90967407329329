import axios from 'axios'
import {resetToken} from "@/utils/getMethods";

axios.defaults.headers.post['Content-Type'] = 'application/json'

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://promo-web.localhost/";
} else if (process.env.NODE_ENV === "test") {
  axios.defaults.baseURL = "https://coupon-test.superincreasemedia.com/";
} else if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://www.powerpromocode.com/";
} else {
  axios.defaults.baseURL = "http://promo-web.localhost/";
}

axios.defaults.timeout = 5000

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response
  },
  function (error) {
    if (error?.response?.data?.code === 401) {
      resetToken()
      window?.location.reload()
    }
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export const BASE_URL = axios.defaults.baseURL


export default axios
